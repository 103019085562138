<style scoped>
.nav-item {
  padding: 0 10px;
}
</style>

<template>
  <b-navbar-nav class="m-auto">
    <b-nav-item href="/about">
      <b-icon v-if="$route.name == 'site-about'" icon="chevron-right" color="#fc0" />
      Quem Somos
    </b-nav-item>

    <b-nav-item href="/opportunity">
      <b-icon v-if="$route.name == 'site-investimentos'" icon="chevron-right" color="#fc0" />
      Investimentos
    </b-nav-item>

    <b-icon v-if="$route.name == 'site-corretor'" icon="chevron-right" color="#fc0" style="margin-top: 3px; margin-right: -8px" />
    <b-icon v-if="$route.name == 'site-construtora'" icon="chevron-right" color="#fc0" style="margin-top: 3px; margin-right: -8px" />
    <b-nav-item-dropdown text="Parceiros">
      <b-dropdown-item href="/corretor">
        <b-icon v-if="$route.name == 'site-corretor'" icon="chevron-right" color="#fc0" />
        Corretor
      </b-dropdown-item>
      <b-dropdown-item href="/construtora">
        <b-icon v-if="$route.name == 'site-construtora'" icon="chevron-right" color="#fc0" />
        Construtora
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item href="/blog">
      <b-icon v-if="$route.name == 'site-blog'" icon="chevron-right" color="#fc0" />
      Divvy Blog
    </b-nav-item>

    <b-nav-item href="/faq">
      <b-icon v-if="$route.name == 'site-faq'" icon="chevron-right" color="#fc0" />
      FAQ
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import { BNavbarNav, BNavItem, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BNavbarNav,
    BNavItem,
    BDropdownItem,
    BNavItemDropdown,
  },
}
</script>
