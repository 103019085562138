export default [
  {
    title: 'Quem Somos',
    route: 'site-about',
    icon: 'HomeIcon',
  },
  {
    title: 'Investimentos',
    route: 'site-oportunity',
    icon: 'BarChartIcon',
  },
  {
    title: 'Parceiros',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Corretor',
        route: 'site-corretor',
        icon: 'UserIcon',
      },
      {
        title: 'Construtora',
        route: 'site-construtora',
        icon: 'UserIcon',
      },
    ],
  },
  {
    title: 'Blog',
    route: 'site-blog',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'FAQ',
    route: 'site-faq',
    icon: 'HelpCircleIcon',
  },
]
