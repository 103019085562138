<template>
  <b-nav-item-dropdown
    v-if="notificationConditionOne"
    class="dropdown-notification mr-25 notification-custom"
    menu-class="dropdown-menu-media"
    right>
    <template #button-content>
      <feather-icon
        :badge="unreadCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="22" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
        <b-badge pill variant="primary" class="notification-custom__badge-light-primary"> {{ totalCount }} mensagens </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li" />
      <!-- Account Notification -->
      <b-link
        v-for="(notification, index ) in notifications"
        :key="index"
        @click="clickNotification()"
        :to="{ name: 'notification-message', params: { id: notification.id, notificationUserId: notification.notificationUserId } }">
        <b-media class="m-notification">
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type" />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.notificationName }}
            </span>
          </p>
          <small class="notification-text">{{ notification.message.substring(0,100)+"..." }}</small>
        </b-media>
      </b-link>
    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="notification-custom__btn-primary"
        block
        :to="{ name : 'notification-list-user'}">
        Leia todas as notificações
      </b-button>
    </li>
  </b-nav-item-dropdown>
  <feather-icon
      v-else-if="notificationConditionTwo"
      :badge="unreadCount"
      badge-classes="bg-danger"
      class="text-body"
      icon="BellIcon"
      size="28" />
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import Moment from 'moment/moment'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
      perfectScrollbarSettings: '',
      totalCount: '',
      unreadCount: '',
      interval
    }
  },
  computed: {
    role() {
      return localStorage.getItem('role')
    },
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    notificationConditionOne() {
      if (this.unreadCount > 0 && this.role.length > 0 && this.role !== 'PRE_BROKER' && this.role !== 'Admin') {
        return true
      }
      return false
    },
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    notificationConditionTwo() {
      if (this.role.length > 0 && this.role !== 'PRE_BROKER' && this.role !== 'Admin') {
        return true
      }
      return false
    },
  },
  mounted() {
    if (this.role.length > 0 && this.role !== 'PRE_BROKER' && this.role !== 'Admin') {
      setTimeout(() => {
        this.findData()
      }, 1000)
      this.interval = setInterval(this.findData, 5000)
    }
  },

  methods: {
    async findData() {
      if(localStorage.getItem('id') === undefined || localStorage.getItem('id') === null) {
        clearInterval(this.interval)
      }

      const response = await this.$http.get(
        '/api/services/app/Notification/GetUserNotifications?MaxResultCount=6&State=0&StateTeste=0',
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
      this.totalCount = response.data.result.totalCount
      this.unreadCount = response.data.result.unreadCount
      this.notifications = response.data.result.items.map(res => ({
        id: res.notification.id,
        notificationUserId: res.id,
        creationTime: this.frontEndDateFormat(res.notification.creationTime),
        notificationName: res.notification.notificationName,
        message: res.notification.message,
        severity: res.notification.severity,
        hasFile: res.notification.hasFile,
        lastSent: res.lastSent ? this.frontEndDateFormat(res.notification.lastSent) : '',
        state: res.state,
      }))

      setTimeout(() => {
        this.perfectScrollbarSettings = {
          maxScrollbarLength: 60,
          wheelPropagation: false,
        }
      }, 5000)
    },
    frontEndDateFormat(value) {
      return Moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    clickNotification(){
      setTimeout(() => {
        this.findData()
      }, 500)
    }
  },
}
</script>

<style lang="scss">
.m-notification {
  margin: 10px;
}
.notification-custom {

  &__btn-primary {
    border-color: var(--color) !important;
    background-color: var(--color) !important;
  }
  &__btn-primary:focus {
    background-color: var(--color) !important;
  }
  &__badge-light-primary {
    background-color: var(--color-bg) !important;
    color: var(--color) !important;
  }
  &__btn-primary:hover {
    border-color: none;
    box-shadow: 0 2px 4px 0 rgb(255 255 255 / 40%) !important;
  }
}
</style>
