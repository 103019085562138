<template>
  <div class="site-container">
    <div class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>

      <!-- Logo -->
      <b-link to="/" class="ml-1">
        <logo-divvy />
      </b-link>

      <!-- Site Links -->
      <site-navbar class="d-none d-lg-flex" />

      <b-navbar-nav class="ml-auto">
        <div v-if="name" style="display: contents;">
            <notification-dropdown class="ml-1" style="padding-top: 0.5em"/>
            <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
              <template #button-content>
                <div class="d-sm-flex d-none user-nav">
                  <p class="user-name font-weight-bolder mb-0">
                    {{ name }}
                  </p>
                  <span class="user-status brand-text text-warning">{{ role }}</span>
                </div>
                <b-avatar size="40" variant="light-primary" badge :src="require('@/assets/images/avatars/no_image.png')" class="badge-minimal" badge-variant="success" />
              </template>
              <div v-if="role !== 'PRE_BROKER'">
                <b-dropdown-item :to="{ name: 'dashboard' }" link-class="d-flex align-items-center">
                  <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
                  <span>Dashboards</span>
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item :to="{ name: 'quiz' }" link-class="d-flex align-items-center">
                  <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
                  <span>Questionário</span>
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item :to="{ name: 'dados-user' }" link-class="d-flex align-items-center">
                  <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
                  <span>Configurações</span>
                </b-dropdown-item>

                <b-dropdown-divider />
              </div>
              <b-dropdown-item v-if="role === 'Admin'" :to="{ name: 'admin-login' }" link-class="d-flex align-items-center">
                <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                <span>Sair</span>
              </b-dropdown-item>
              <b-dropdown-item v-else :to="{ name: 'login' }" link-class="d-flex align-items-center">
                <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                <span>Sair</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
        </div>

        <div v-else>
          <!-- <div style="width:100px;">&nbsp;</div> -->
          <div style="position: absolute; right: 0px; margin-top: -18px">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" right variant="flat-dark" :to="{ name: 'login-profile' }" class="mr-1 ml-2"> Login </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" right variant="dark" :to="{ name: 'register-profile' }"> Cadastre-se </b-button>
          </div>
        </div>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
import {
  // BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BLink,
  // BImg,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
// import SearchBar from './components/SearchBar.vue'
import NotificationDropdown from '../../../layouts/navbar/components/NotificationDropdown.vue'
import LogoDivvy from '@/views/site/components/LogoDivvy.vue'
import SiteNavbar from '@/views/site/components/SiteNavbar.vue'

export default {
  components: {
    // BLink,
    SiteNavbar,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BLink,
    // BImg,
    // SearchBar,
    NotificationDropdown,
    LogoDivvy,
    // Navbar Components
    // DarkToggler,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    name() {
      return localStorage.getItem('name')
    },
    role() {
      return localStorage.getItem('role')
    },
  },
  setup() {
    const searchQuery = false
    const { appLogoImage } = $themeConfig.app
    return {
      searchQuery,
      appLogoImage,
    }
  },
}
</script>
<style lang="scss">
.brand-logo > img {
  width: 36px;
}
.main-menu.menu-light .navigation > li ul .active > a {
  color: #ecaf42;
}
</style>
