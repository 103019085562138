<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!--    &lt;!&ndash; Left Col &ndash;&gt;-->
    <!--    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">-->
    <!--      <dark-Toggler class="d-none d-lg-block" />-->
    <!--    </div>-->
    <!-- Icon -->
    <b-link
      v-if="$route.meta.logo === 'show'"
      class="nav-link align-items-center flex-grow-1 d-none d-lg-flex"
      to="/">
      <span class="log_divvy" />
    </b-link>
    <ul class="nav">
      <search-bar v-if="$route.meta.search !== 'hide' && name !== ''" />
    </ul>

    <b-navbar-nav
        v-if="name"
        class="nav align-items-center ml-auto">
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ name }}
            </p>
            <span class="user-status brand-text text-warning-role">{{ role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/no_image.png')"
            class="badge-minimal"
            badge-variant="success" />
        </template>
      <div v-if="role !== 'PRE_BROKER'">
          <b-dropdown-item
            :to="{ name: 'dashboard' }"
            link-class="d-flex align-items-center">
            <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
            <span>Dashboard</span>  
          </b-dropdown-item>

          <b-dropdown-divider />
        <div v-if="role === 'COMPANY'">
          <b-dropdown-item
            :to="{ name: 'company-account' }"
            link-class="d-flex align-items-center">
            <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
            <span>Configurações</span>
          </b-dropdown-item>

          <b-dropdown-divider />
        </div>
        <div v-else-if="role == 'INVESTOR'">
          <b-dropdown-item
            :to="{ name: 'quiz' }"
            link-class="d-flex align-items-center">
            <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
            <span>Questionário</span>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item
            :to="{ name: 'dados-user' }"
            link-class="d-flex align-items-center">
            <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
            <span>Configurações</span>
          </b-dropdown-item>

          <b-dropdown-divider />
        </div>
      </div>
        <b-dropdown-item v-if="role === 'Admin'"
          :to="{ name: 'admin-login' }"
          link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Sair</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-else-if="role === 'COMPANY'"
          :to="{ name: 'company-login' }"
          link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Sair</span>
        </b-dropdown-item>
        <div  v-else-if="role === 'BROKER'">
          <b-dropdown-item
            :to="{ name: 'dados-user' }"
            link-class="d-flex align-items-center">
            <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
            <span>Configurações</span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            :to="{ name: 'broker-login' }"
            link-class="d-flex align-items-center">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Sair</span>
          </b-dropdown-item>
        </div>
        <b-dropdown-item
          v-else-if="role === 'PRE_BROKER'"
          :to="{ name: 'broker-login' }"
          link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Sair</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-else
          @click="toLogin"
          link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-button
      v-else
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="flat-primary"
      :to="{ name: 'login-profile' }">
      Efetuar login
    </b-button>
  </div>
</template>

<script>
import {
  // BLink,
  BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton, BLink,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import SearchBar from './components/SearchBar.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'

export default {
  components: {
    // BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BLink,
    SearchBar,
    NotificationDropdown,

    // Navbar Components
    // DarkToggler,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    name() {
      return localStorage.getItem('name')
    },
    role() {
      return localStorage.getItem('role')
    },
  },
  setup() {
    const searchQuery = false
    const { appLogoImage } = $themeConfig.app
    return {
      searchQuery,
      appLogoImage,
    }
  },

  methods: {
    toLogin(){
      localStorage.removeItem('immobileIdSelected')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
<style lang="scss">
.brand-logo > img {
  width: 36px;
}
.main-menu.menu-light .navigation > li ul .active > a {
  color: var(--color) !important;
}
ul.dropdown-menu > li > a:hover,
ul.dropdown-menu > div > li > a:hover,
ul.dropdown-menu > div > div > li > a:hover  {
  color: var(--color) !important;
  background-color: var(--color-bg) !important;
}
.text-warning-role {
    color: var(--color) !important;
}
.log_divvy {
  background-color: var(--color);
  -webkit-mask: url('~@/assets/images/logo/logo_divvy.svg') center / contain no-repeat;
  min-width: 61px;
  min-height: 44px;
}
</style>
