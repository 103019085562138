<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header">
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to">
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="$route.meta.back === true"
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn btn-sm btn-primary ml-2 float-right btn-custom"
        variant="primary"
        @click="$router.go(-1)">
        Voltar
      </b-button>
    </b-col>
    <b-col
      v-if="$route.meta.back_notification === true"
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn btn-sm btn-primary ml-2 float-right btn-custom"
        variant="primary"
        @click="$router.push({ name: 'notification-list-user' })">
        Voltar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BButton,
  // BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
    // BDropdown, BDropdownItem, BButton,
  },
  data() {
    return {
      dataUser: {
        id: localStorage.getItem('id'),
      },
    }
  },
  methods: {
    async alterData() {
      try {
        const responseUser = await this.$http.get(
          `/api/services/app/User/GetUserForEdit?Id=${this.dataUser.id}`,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )

        const roleName = responseUser.data.result.roles
        const RoleNames = []
        roleName.forEach(element => {
          RoleNames.push(element.roleName)
        })

        const dataUser = {
          user: {
            id: this.dataUser.id,
            name: responseUser.data.result.user.name,
            phoneNumber: responseUser.data.result.user.phoneNumber,
            emailAddress: responseUser.data.result.user.emailAddress,
            surname: responseUser.data.result.user.surname,
            userName: responseUser.data.result.user.userName,
            typePerson: responseUser.data.result.user.typePerson,
            quiz: false,
            termsOfUse: true,
            isActive: true,
            shouldChangePasswordOnNextLogin: false,
            isTwoFactorEnabled: false,
            isLockoutEnabled: false,
          },
          AssignedRoleNames: RoleNames,
        }
        const response = await this.$http.post(
          '/api/services/app/User/CreateOrUpdateUser',
          dataUser,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        if (response) {
          this.msgSuccess()

          setTimeout(() => {
            this.$router.push('/dashboard')
          }, 2700)
        }
      } catch (error) {
        this.msgError()
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados gravados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>

a {
    color: var(--color) !important;
}
.btn-custom {
  border-color: var(--color) !important;
  background-color: var(--color) !important;
  box-shadow: 0 2px 4px 0 rgb(255 255 255 / 40%) !important;
}
</style>
