// export default {
//   admin: [
//     {
//       title: 'Dashdoard',
//       route: 'dashboard',
//       icon: 'HomeIcon',
//     },
//     {
//       title: 'Usuários',
//       route: 'user-list',
//       icon: 'UsersIcon',
//     },
//     {
//       title: 'Corretor',
//       icon: 'UsersIcon',
//       children: [
//         {
//           title: 'Deliberação',
//           route: 'deliberation',
//         },
//         {
//           title: 'Corretores',
//           route: 'broker-list',
//         },
//       ],
//     },
//     {
//       title: 'Notificação',
//       icon: 'BellIcon',
//       children: [
//         {
//           title: 'Em Aberto',
//           route: 'notification-list',
//         },
//         {
//           title: 'Cadastrar',
//           route: 'notification-register',
//         },
//       ],
//     },
//     {
//       title: 'Questionário',
//       route: 'quiz-control',
//       icon: 'MenuIcon',
//     },
//   ],
//
//   broker: [
//     {
//       title: 'Dashdoard',
//       route: 'dashboard',
//       icon: 'HomeIcon',
//     },
//   ],
// }
export default JSON.parse(localStorage.getItem('menu'))
