<template>
  <p class="clearfix mb-0" />
</template>

<script>
// import { BLink } from 'bootstrap-vue'

export default {
  components: {
    // BLink,
  },
}
</script>
